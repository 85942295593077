<template>
    <div class="employee-entry" v-bind:class="{'summary': summary, 'hide-shadow': hideShadow}">
        <employeepicture class="picture" :employee="state.employee"></employeepicture>
        <div class="name" v-if="!splitName" v-bind:style="textStyle">{{name}}</div>
        <div class="name first" v-if="splitName" v-bind:style="textStyle">{{firstName}}</div>
        <div class="name last" v-if="splitName" v-bind:style="textStyle">{{lastName}}</div>
        <div class="name shadow"></div>
        <div class="color" v-if="color" v-bind:style="{'background-color': color}"></div>
    </div>
</template>

<script>
    export default {
        name: "employeeentry",
        data(){
            return{
                firstName: '',
                lastName: '',
                summary: false,
                splitName: false,
                color: null,
                hideShadow: false,
                textStyle: null,
            }
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];

                }
            }
        },
        computed: {
            name(){
                return this.firstName + ' ' + this.lastName;
            }
        },
        props:{
            state: Object,
        }
    }
</script>

<style scoped>
    .employee-entry{
        position: relative;
        padding-left: 30px;
        height: 100%;
        width: 100%;

    }

    .employee-entry .name{
        font-weight: 800;
        font-family: DistrictProBold;
        font-size: 13px;
        color: var(--contrast-5);
        position: absolute;
        left: 30px;
        width: calc(100% - 30px);
        top: 5px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
    }

    .employee-entry .color{
        position: absolute;
        right: -9px;
        top: 5px;
        width: 6px;
        height: 30px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .employee-entry .shadow{
        font-weight: 800;
        font-family: DistrictProBold;
        font-size: 13px;
        color: var(--contrast-7);
        position: absolute;
        left: 30px;
        width: calc(100% - 30px);
        top: 5px;
        text-align: left;
        box-shadow: inset -6px 0px 3px -3px var(--bg) !important;
        height: 50%;
    }

    .row:hover  .employee-entry .shadow{
        /*box-shadow: inset -6px 0px 3px -3px var(--contrast-3) !important;*/
    }

    .data-table.compact .row:nth-child(2n - 1) .employee-entry{
        background: var(--contrast-1);
    }
    .data-table.compact .row:nth-child(2n - 1) .employee-entry .shadow{
        box-shadow: inset -6px 0px 3px -3px var(--contrast-1) !important;
    }

    .employee-entry .picture{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 25px;
        width: 25px;
    }
    .employee-entry.summary {
        padding: 0;
    }
    .employee-entry.summary .picture{
        display: none;
    }
    .employee-entry.summary .name{
        top: 0px;
        left: 5px;
        width: calc(100% - 5px);
        white-space: nowrap;
    }
    .employee-entry.summary .shadow{
        top: 0px;
        left: 5px;
        width: calc(100% - 5px);
    }

    .employee-entry .name.first{
        left: 30px;
        top: 0px;
        font-family: DistrictProBook;
    }
    .employee-entry .name.last{
        left: 30px;
        bottom: 2px;
        top: initial;
    }
    .employee-entry.summary .shadow, .hide-shadow .shadow{
        display: none;
    }

    .collapsed .name{
        color: var(--contrast-4) !important;
    }
</style>
